.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    color: white !important;
}

.css-1deacqj {
    color: white !important;
}
.BottomMenuBar {
    top: auto;
     bottom: 0;
     background-color: '#ff726f' ;
}