h2 {
text-align: center;
}
  
.logout-button2 {
  background-color: #ffc107; /* Match the yellow button color */
  border: none;
  color: black;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin: auto;
  display: flex;
}


.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background: #fff;
    /* box-shadow: 2px 0 5px rgba(0,0,0,0.5); */
    overflow: scroll;
    transition: left 0.3s ease;
    z-index: 1000;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .close-button {
    font-size: 24px;
    cursor: pointer;
    border: none;
    background: none;
    color: white;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    color: #000;
    margin-bottom: 50px;
  }
  
  .menu li:hover {
    background: #f0f0f0;
  }
  .clrblack {
    color: #000 !important;
    font-weight: 500;
  }
  