.ludo {
    text-align: center;
  }
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .input-container span {
    background-color: white;
    padding: 8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .input-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
  }
  
  .game-room {
    padding: 15px;
  }
  
  .players-info {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #8e8ef1;
    border-radius: 10px
  }

  .userimage{
    width: 50px;
  }
  
  .player-info {
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    display: contents;
  }
  
  .room-code {
    margin: 20px 0;
    background-color: #5f5b5b;
    border-radius: 10px;
    color: white;
    padding: 10px;
  }
  
  .room-code button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .room-code button:hover {
    background-color: #45a049;
  }
  
  .app-links {
    background-color: #8e8ef1;
    padding: 10px;
    border-radius: 10px;
    margin: 20px 15px;
  }
  
  .app-links img {
    margin: 0 10px;
    width: -webkit-fill-available;
  }
  
  .game-rules {
    text-align: left;
    margin: 20px 15px;
  }
  
  .game-rules ul {
    list-style-type: none;
    padding: 0;
  }
  
  .game-rules li {
    margin: 10px 0;
  }
  
  .gamestatus {
    text-align: left;
    margin: 20px 15px;
  }
  
  .gamestatus button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .game-status button:hover {
    background-color: #45a049;
  }

  .EditRoom {
    background-color: #ffc107;
    width: auto;
    padding: 10px;
    border-radius: 4px;
    margin: 5px;
  }

  .winning {
    background-color: #45a049;
    width: 95%;
    padding: 10px;
    border-radius: 4px;
    margin: 5px;
  }
  