.container {
margin-top: 0rem;
margin-bottom: 0rem;
padding-top: 85px;
padding-bottom: 120px;
/* background-image: url('../../public/assets/images/bg/gamebackground9.jpg'); */
background-color: #000;
background-size: cover;
min-height: 100vh;
color: #fff;
}
.theme-colour{
    background-color: #000 !important;
  }

.themecolour2 {
    background-color: #2c6dff !important;
  }
  .themecolour3 {
    background-color: transparent !important;
  }
  