/* src/styles/Support.css */
.support-container {
    background-color: #00000063;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin: 10px;
    border-radius: 20px;
  }
  
  .support-heading {
    margin-bottom: 20px;
  }
  
  .contact-item {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .address {
    margin-top: 20px !important;
    font-size: 14px;
  }
  