.refer-container {
    background-color: #00000063;
    color: #fff;
    padding: 20px;
    max-width: 300px;
    margin: auto;
    border-radius: 10px;
    text-align: center;
}

.refer-code-section {
    margin-bottom: 20px;
}
.referral-earnings-section {
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 300px; /* Adjust width according to your layout */
    margin: 0 auto; /* Center align for the example */
    text-align: center;
  }

.referral-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.referral-code input {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 5px 10px;
    margin-right: 10px;
    width: 100px;
    text-align: center;
    border-radius: 5px;
}

.referral-code button {
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.referral-code button:hover {
    background-color: #cc0000;
}

.social-icons button {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.social-icons button:hover {
    background-color: #444;
}

.referral-earnings-section h4 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  .earnings-details {
    display: flex;
    flex-direction: column;
    gap: 0px; /* Space between each item */
  }
  .earning-item {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between icon and text */
    padding: 10px;
  }

.earnings-details div {
    flex: 1;
    padding: 0px;
    background-color: transparent;
    border-radius: 5px;
    margin: 5px;
}

.earnings-details p {
    margin: 5px 0;
}
.earning-info {
    text-align: left;
  }
  
  .label {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .value {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
  }
  .icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-icons {
    margin-top: 20px;
  }
  
  .whatsapp-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #25D366; /* WhatsApp green */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .whatsapp-button i {
    margin-right: 8px; /* Space between icon and text */
    font-size: 20px;   /* Adjust icon size */
  }
  
  .whatsapp-button:hover {
    background-color: #1DA851; /* Darker green on hover */
  }