.transaction-history {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #000; /* Added background color for the container */
  max-width: 600px; /* Center container with max-width */
  margin: 0 auto; /* Center the component on the page */
  border-radius: 10px; /* Added rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.transaction-history h2 {
  font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    padding: 8px;
    border-radius: 10px;
    color: #fff;
    background-color: #007bff;
}

.transaction-history p {
  margin-bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-transactions{
  text-align: center;
  color: gray;
}

.transaction-history .tabs {
  display: flex;
  justify-content: center; /* Centered tabs */
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.transaction-history .tabs button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 5px; /* Rounded corners for buttons */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.transaction-history .tabs .active {
  background-color: #007bff;
  color: white;
}

.transaction-history .transactions {
  display: flex;
  flex-direction: column;
}

.transaction-history .transaction {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #000;
  margin-bottom: 5px;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.transaction-history .transaction:hover {
  background-color: #f1f1f1; /* Slight change on hover for feedback */
}

.transaction-history .transaction-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-history .transaction-details img {
  width: 30px; /* Smaller icon */
  height: 30px;
  object-fit: cover; /* Maintain aspect ratio */
}

.transaction-history .transaction-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px; /* Added gap between items */
}

.transaction-history .transaction-info p {
  margin: 0;
  color: #666; /* Slightly subdued color for info text */
  font-size: 14px; /* Slightly smaller font for details */
}

.transaction-history .transaction-amount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  font-weight: bold; /* Bold text for emphasis */
  align-items: flex-end;
}
/* Loading spinner styles */
.loading-spinner {
  display: flex;
  width: 80px;
  height: 80px;
  border: 6px solid rgba(255, 255, 255, 0.3); /* Light grey */
  border-top-color: #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.transaction-amount,.transaction-info,.transaction-date {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.transaction-history .transaction-amount .amount {
  color: #28a745; /* Green for positive amounts */
}

.transaction-history .transaction-amount .closing-balance {
  font-size: 12px; /* Smaller font size for balance */
  color: #999; /* Grey color for closing balance */
}

.transaction-history .pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

.transaction-history .pagination button {
  padding: 5px 10px;
  border: none;
  background-color: #9f9f9f;
  cursor: pointer;
  border-radius: 3px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.transaction-history .pagination button:hover {
  background-color: #007bff; /* Slight change on hover for feedback */
}

.transaction-history .pagination .active {
  background-color: #007bff;
  color: white;
}
.Credit {
  color: green !important; /* Color for credit transactions */
}

.Debit {
  color: red !important; /* Color for debit transactions */
}