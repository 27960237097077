.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.modalOpen {
  transform: translateY(0);
}

.modalContent {
  padding: 0px;
}

.modalHeader {
  padding: 16px;
  border-radius: 16px 16px 0 0;
  text-align: center;
}

.modalTitle {
  font-size: 18px;
  color: white;
}

.modalSelect {
  margin-top: 8px;
  color: #000;
  background: white;
  padding: 5px;
  border-radius: 5px;
}

.modalBody {
  padding: 16px 16px;
}

.modalLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: #000;
}
.modalLine2 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.modalLineList {
  display: flex;
  gap: 2px;
}

.modalLineItem {
  padding: 8px 16px;
  background-color: #f6f6f6;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
}

.activeItem {
  color: white;
}

.modalLineBtn {
  display: flex;
  align-items: center;
}

.modalBtn {
  padding: 8px;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.modalInput {
  width: 50px;
  text-align: center;
  border: 1px solid #FFA84D;
  border-radius: 8px;
  margin: 0 8px;
  padding: 5px;
}

.modalCheckbox {
  margin-right: 8px;
}

.modalAgree {
  margin-right: 8px;
  color: #000;
}

.modalPreSale {
  color: #fb5b5b;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 16px 16px;
}

.modalCancel {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  width: 70% !important;
  border-radius: 0;
}

.modalConfirm {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 100% !important;
  border-radius: 0;
}
