/* Auth.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.resendOtpBtn {
  margin-left: 10px; /* Adjust as needed */
}

.auth-container {
  display: flex;
  background-image: url('../../public/assets/images/splash-bg.png');
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 95vh;
  padding: 20px;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
.auth-header{
  color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}

.logo2 {
  max-width: 20%;
  height: auto;
  margin: auto;
}

.auth-button {
  background-color: #ffce00;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: auto;
  margin: auto;
}

.auth-form {
  background-color: #02020242;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  box-shadow: inset 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%; /* Make sure the form doesn't exceed the viewport width */
  box-sizing: border-box;
}

.input-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.input-container span {
  background-color: white;
  padding: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
}

.otp-button {
  background-color: #ef1818;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  width: 100%; /* Make it responsive */
  max-width: 400px;
  box-sizing: border-box;
}

.footer-text {
  margin-top: 20px;
  font-size: 14px;
  color: white;
  font-family: sans-serif;
}

.footer-text a {
  color: #007bff;
  text-decoration: none;
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  background-color: #f7f7f7;
  box-sizing: border-box;
}

.footer nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer nav ul li {
  margin: 0 10px;
}

.footer nav ul li a {
  text-decoration: none;
  color: black;
}

.login-footer {
  font-size: 10px;
}
