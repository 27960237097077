.ludo-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #1d1f27;
    border-radius: 12px;
    color: #ffffff;
  }
  
  .create-battle {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-battle-input input {
    padding: 10px;
    margin-right: 10px;
    width: 60%;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .create-battle-input button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: auto !important;
  }
  
  .open-battles {
    background-color: #2c2f38;
    border-radius: 8px;
    padding: 10px;
  }
  .opening-battles {
    background-color: #2c2f38;
    border-radius: 8px;
    padding: 0px;
  }
  .running-battles {
    margin-top: 10px;
    background-color: #2c2f38;
    border-radius: 8px;
    padding: 10px;
  }
  
  .battle {
    background-color: #1e2231;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  .battle p {
    margin: 0 0 10px;
    font-weight: bold;
  }
  
  .battle-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .entry-fee,
  .prize {
    text-align: center;
  }
  
  .start-battle,
  .delete-battle {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .delete-battle {
    background-color: #dc3545;
  }
  
  .start-battle {
    background-color: #28a745;
  }
  
  img {
    display: inline-block;
    margin-right: 10px;
  }
  