.hearderhome {
border: 1px solid #faa3ad !important;
    border-radius: .375rem;
    font-family: Poppins, sans-serif;
    line-height: 16px;
    font-size: .65rem !important;
    display: flex !important;
    align-items: center !important;
}

@keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .movingclass {
    display: inline-block; /* Ensures the content stays inline */
    animation: marquee 10s linear infinite; /* Apply the marquee animation */
    white-space: nowrap; /* Prevents text wrapping */
    will-change: transform; /* Optimizes for animations */
  }
  .speaker {
    background-color: #000;
    z-index: 1000;
  }