/* src/styles/KYC.css */
.kyc-inputbox {
  background: #fff;
  border-radius: 5px;
}

.kyc-container {
    background-color: #00000063;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .kyc-heading {
    margin-bottom: 10px;
  }
  
  .kyc-subheading {
    margin-bottom: 20px !important;
    font-size: 12px !important;
  }
  
  .kyc-form {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .upload-button {
    background-color: #9C27B0;
    color: #fff;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .upload-icon {
    margin-right: 10px;
  }
  
  .submit-button {
    background-color: #f44336;
    color: #fff;
    margin-top: 20px !important;
  }
  .image-preview {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .image-preview-img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }