/* Aviator.css */

.aviator-container {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.balance {
  font-size: 18px;
}

.game-area {
  position: relative;
  height: 400px;
  margin: 20px 0;
  background: url('../assets/grey-texture-bg.png');
  border-radius: 10px;
  overflow: hidden;
  z-index: 1; 
}

.multiplier-display {
  font-size: 36px;
  margin-top: 20px;
  z-index: 4
}

/* .static-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/bg-rotate-old.svg');
  background-size: cover;
  background-position: center;
  z-index: 1; /* Ensures the static background is behind other elements 
} */

.rotating-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 2190px;
  height: 2190px;
  top: -722px;
  left: -1095px;
  background-image: url('../assets/bg-rotate-old.svg'); /* Replace with your background image */
  background-size: cover;
  background-position: center;
  animation: rotate 20s linear infinite;
  z-index: 2; /* Ensure it stays behind the plane */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.plane {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.01s linear;
  width: 100px;
  z-index: 4;
}

.bet-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.bet-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.bet-input-field {
  width: 80px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  text-align: center;
}

.bet-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.bet-button:hover {
  background-color: #45a049;
}

.bets-table {
  margin-top: 20px;
}

.bets-table table {
  width: 100%;
  border-collapse: collapse;
}

.bets-table th, .bets-table td {
  border: 1px solid #333;
  padding: 10px;
  text-align: left;
}

.bets-table th {
  background-color: #555;
}

.bets-table td {
  background-color: #222;
}

.history-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.history-button:hover {
  background-color: #45a049;
}

.flew-away-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
}

.close-popup-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.close-popup-button:hover {
  background-color: #e53935;
}
.loading-screen {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.loading-screen p {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.loading-screen svg {
  width: 80px;
  height: 80px;
}

