.wallet-container {
    padding: 20px;
  }
  
  .wallet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4c4c9b4a; /* Match the purple color */
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: white;
  }
  
  .wallet-header h1 {
    margin: 0;
  }
  
  .order-history-button {
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: auto;
  }
  
  .wallet-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .wallet-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    background: linear-gradient(to bottom right, #ff616f, #ff8e53);
  }
  
  .wallet-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .wallet-card-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .wallet-card h3 {
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .coins {
    background-color: #ffc107; /* Coin color */
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 12px;
  }
  
  .wallet-card-button {
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  