.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffe3e3;
    min-height: 100vh;
    padding: 10px;
    font-family: Arial, sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ff726f;
    color: white;
    border-radius: 10px;
    margin-bottom: 15px;
    width: 100%;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.walletInfo {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.smallbutton {
    background-color: #6ea8f4 !important;
    border-radius: 0 1.93333rem 1.93333rem 0 !important;
    margin: 0 !important;
    font-size: .82667rem;
    width: 9.13333rem;
    height: 2.36rem;
    text-align: center;
}

.bigbutton {
    background-color: #feaa57 !important;
    border-radius: 1.93333rem 0 0 1.93333rem !important;
    margin: 0 !important;
    font-size: .82667rem;
    width: 9.13333rem;
    height: 2.36rem;
    text-align: center;
}

.greenbutton {
    background-color: #18b660 !important;
    border-radius: 0 .6667rem !important;
}

.violetbutton {
    background-color: #c86eff !important;
    border-radius: .3333rem !important;
}

.redbutton {
    background-color: #fb5b5b !important;
    border-radius: .6667rem 0 !important;
}

.withdrawButton,
.depositButton {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.bettingsection {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 25px;
}

.withdrawButton {
    background-color: #ff4040;
}

.depositButton {
    background-color: #32cd32;
}

.mainContent {
    margin-top: 0px;
    width: 100%;
}

.instruction {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
    color: #000;
}

.instruction a {
    color: #ff4040;
    text-decoration: none;
}

.gameOptions {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.gameOptions button {
    width: 25%; /* Adjust the width to match your needs */
    height: 100%; /* Adjust the height to match your needs */
    padding: 5px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
    background-color: transparent; /* Remove background color */
    display: flex;
    color: #333;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gameOptions button img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
}

.activeOption {
    background: linear-gradient(90deg, #f95959 0%, #ff9a8e 100%);
    color: white !important;
}

.inactiveOption {
    background-color: #ececec;
    color: #333;
}

.activeOption img,
.inactiveOption img {
    filter: none; /* Ensure images are not filtered or affected by button state */
}

.activeOption:hover,
.inactiveOption:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.timer {
    display: flex;
    align-items: center;
    width: calc(100% - 1rem);
    height: 4.66667rem;
    background: url('../assets/timerbackground.png') no-repeat center center / cover;
    margin: 0.5rem auto 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.imageStyle {
    width: 12px;
    height: auto;
    margin: 0 1px;
    margin-top: 10px;
    width: 25px;
}

.howToPlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:start;
    flex: 1;
    color: #fff;
    margin-right: 15px;
}

.Remaningtimer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    flex: 1;
    color: #fff;
}

.playbutton {
    font-size: 12px;
    margin-top: 5px;
}

.howToPlay button {
    padding: 5px 10px;
    border: white solid;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
}

.time,
.gameId {
    font-size: 14px;
    font-weight: 700;
    font-weight: bold;
    color: #ffffff;
    flex: 1;
    text-align: center;
}

.numbers {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.balls {
    display: flex;
    gap: 10px;
}

.ball {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ff4040;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.gameButtons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.gameButton {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    color: white;
}

.gameButton:nth-child(1) {
    background-color: #18b660;
}

.gameButton:nth-child(2) {
    background-color: #c86eff;
}

.gameButton:nth-child(3) {
    background-color: #fb5b5b;
}

.numberOptions {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}

.ballImage {
    width: 18%; /* Adjust to fit 5 items per row */
    margin-bottom: 10px;
    text-align: center;
}

.ballImage img {
    max-width: 100%;
  height: auto;
}

.betOptions {
    display: flex;
    justify-content: center;
    gap: 0px;
    margin-bottom: 10px;
}

.betOptions button {
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    background-color: #ff726f;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.betChoice {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-bottom: 20px;
}

.betChoice2 {
    display: flex;
    justify-content: center;
    gap: 0px;
    margin-bottom: 20px;
}

.betChoice button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #ff4040;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #ff726f;
    padding: 10px 0;
    border-radius: 10px;
    margin-top: 10px;
}

.footer button {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

/* New styles for blur and timer highlight */
.contentBlurred {
    filter: blur(5px);
    pointer-events: none;
}

.timerHighlighted {
    font-size: 2em;
    color: red;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    display: none; /* By default, hide the timer */
}

/* Additional CSS changes for buttons */
.buttonHover:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    border-radius: 20px;
  }
  
  .overlayTimer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50rem;
    color: red;
  }
  
  .bettingsection {
    position: relative;
  }
  .boxStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 25px;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    margin: 0 2px;
}
.boxStyle2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 160px;
    background-color: #fff;
    color: #ff726f;
    font-weight: bold;
    font-size: 140px;
    margin: 0 25px;
    border-radius: 20px;
}
.modal {
    /* Your existing modal styles */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure modal is above the overlay */
  }
  
  .modalOpen {
    transform: translateY(0);
  }

  .modalLine2 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .modalLineList {
    display: flex;
    gap: 2px;
    justify-content: space-around;
    margin-bottom: 10px;
    
  }
  
  .modalLineItem {
    padding: 8px 10px;
    background-color: #f6f6f6;
    border-radius: 8px;
    color: #000;
    cursor: pointer;
  }
  .activeItem {
    color: white;
    background-color: #18b660;
  }

  .overlayBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity to make it dim */
    z-index: 999; /* Ensure it's above other content */
    display: flex;
    align-items: center;
    justify-content: center;
  }