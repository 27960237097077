.add-cash-container {
    background-color: #00000063;
    color: #fff;
    padding: 10px 30px 10px 30px;
    max-width: 300px;
    margin: auto;
    border-radius: 10px;
    text-align: center;
}
.Addcashheading {
    margin: auto;
    text-align: center;
    max-width: 300px;
}
.AccountDetails {
    display: grid;
    margin-bottom: 10px;
    box-shadow: #6c575726 17px 2px 0px 13px;
    background-color: #09090900;
    border-radius: 10px;
    padding: 10px;
}

.input-section {
    margin-bottom: 20px;
}

.amount-input {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
}

.amount-input input {
    background-color: transparent;
    border: none;
    margin-left: 5px;
    width: 100%;
    color: #000;
    font-size: 16px;
}

.preset-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.preset-buttons button {
    background-color: #00008b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: auto;
    margin: 5px;
}

.preset-buttons button:hover {
    background-color: #0000cd;
}

.next-button {
    background-color: #800080;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: auto;
}

.next-button:hover {
    background-color: #9932cc;
}

.refer-code{
    margin-top: 10px;
}
.notedetails{
    font-size: 12px;
    font-family: sans-serif;
}