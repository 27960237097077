.profile-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #00000063; /* Semi-transparent black background */
  color: white;
  border-radius: 10px;
}

.EditButton {
  padding: 6px;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.input-container {
  display: flex;
  align-items: center;
}

input[type="text"] {
  /* Style for the text input field */
  padding: 10px;
  font-size: 16px;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.display-name {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #1c1c1c; /* Dark background for the header */
}

.profile-info {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.wallet-button {
  /* Style for the wallet button */
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Button color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.profile-section {
  margin-bottom: 20px;
}

.profile-section h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #ffffff; /* White color for headings */
}

.profile-action-button {
  display: flex;
  align-items: center;
  background-color: #8c52ff; /* Purple button color */
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 95%;
  margin-bottom: 10px;
}

.profile-action-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.details-cards {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.details-card {
  background: linear-gradient(230deg, #759bff, #843cf6) !important;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.logout-button {
  background-color: #ffc107; /* Yellow button color */
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.edit-name, .edit-email {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

input[type="email"] {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.display-email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
