/* src/styles/Game.css */

.gamecardboxd {
    transition: transform 0.2s ease-in-out;
  }
  
  .gamecardboxd:hover {
    transform: scale(1.05);
  }
  
  .game-status {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .live {
    background-color: green;
    color: white;
    animation: blink 3s infinite; /* Blink animation for the live status */
  }
  
  .coming-soon {
    background-color: gray;
    color: white;
  }
  
  /* Blinking animation */
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  