.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 500px;
}

.popup__action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.popup--button1 {
  width: 200px;
  height: 50px;
}

.popup--button2 {
  width: 200px;
  height: 50px;
  background-color: #ccc;
  transition: all 0.3s;
  &:hover {
    background-color: #8d8b8b;
  }
}

.popup--button3 {
  width: 250px;
  height: 50px;
  background-color: rgb(13, 84, 199);
  transition: all 0.3s;
  &:hover {
    background-color: rgb(11, 61, 142);
  }
}

.popup--imageRef {
  display: none;
}

.popup__imageUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    &:not(:first-child) {
      margin-left: 1rem;
    }
    font-size: 15px;
  }
}

.popup__uploadAction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__uploadText {
  margin-top: 10px;
  color: rgb(144, 6, 6);
  font-weight: bold;
  font-style: italic;
}

@media only screen and (max-width: 70rem) {
  .popup-content {
    padding: 10px;
    width: 300px;
  }

  .popup--button1 {
    width: 100px;
    height: 35px;
  }

  .popup--button2 {
    width: 100px;
    height: 35px;
  }

  .popup--button3 {
    width: 200px;
    height: 45px;
  }

  .popup__imageUpload {
    > * {
      &:not(:first-child) {
        margin-left: 0.5rem;
      }
      font-size: 13px;
    }
  }

  .popup__uploadText {
    margin-top: 8px;
    font-size: 14px;
  }
}
