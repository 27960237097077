.logo{
  width: 60px;
  height: 60px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ff726f;
    padding: 10px;
    color: white;
    position: relative;
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .nav-menu {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #ff726f;
    width: 100%;
    text-align: right;
  }
  
  .nav-menu.active {
    display: block;
  }
  
  .nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-menu ul li {
    padding: 10px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }
  
  .nav-menu ul li:hover {
    background-color: #ff726f;
  }
/* src/styles/Header.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .menu-icon {
    cursor: pointer;
    z-index: 1000; /* Ensure the menu icon is above the overlay */
  }
  .header_top_message {
    color: #fff !important;
    font-family: Poppins, sans-serif !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 17px;
    max-width: -webkit-fill-available;
    padding: 8px;
    position: relative;
    text-align: center;
    top: 0;
    width: 480px;
    width: 100%;
    z-index: 999;
}  

.withdrawButton {
  padding: 5px;
  margin: 5px;
  box-shadow: #00000026 1.95px 1.95px 2.6px;
  border-radius:8px;
  color: #fff;
  width: auto;
}

.depositButton {
  background-color: #32cd32;
  padding: 5px;
  margin: 5px;
  box-shadow: #00000026 1.95px 1.95px 2.6px;
  border-radius:8px;
  color: #fff;
  width: auto;
}

